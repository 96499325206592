
export default {
  props: {
    height: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    logoSrc() {
      const localeLogo =
        this.$i18n.locale === 'en'
          ? require('@/assets/images/logo-en.png')
          : require('@/assets/images/logo.png');
      return localeLogo;
    },
  },
};
